.welcome-card img {
  max-height: 35vh;
  overflow: hidden;
}
ion-button {
  margin-left: auto;
  margin-right: auto;
}
ion-item.red-background {
  --ion-background-color: red;
}
ion-item.red-background h2 {
  color: white;
}

ion-item.red-background p {
  color: white;
}